.color-text {
  &.is-positive {
    @apply text-green-500 dark:text-green-400;
  }
  &.is-negative {
    @apply text-red-500 dark:text-red-400;
  }
  &.is-muted {
    @apply text-gray-500 dark:text-gray-300;
  }
}
.has-color-text:hover .color-text {
  &.is-positive {
    @apply text-green-600 dark:text-green-200;
  }
  &.is-negative {
    @apply text-red-600 dark:text-red-200;
  }
  &.is-muted {
    @apply text-gray-600 dark:text-gray-200;
  }
}
