body.is-forex,
body.is-crypto {
  .chart-menu_chart-preview {
    @media (max-width: 1240px) {
      @apply mx-0.5 my-0 w-[83px] overflow-hidden;

      svg {
        @apply float-right;
      }
    }
  }
}

.forex_change-switch {
  @apply text-sm text-muted-3;
}
.forex_change-label {
  @apply text-muted-2 font-medium;
}
