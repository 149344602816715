.badge {
  @apply h-5 min-w-5 rounded-full bg-gray-200/30 px-1.5 py-0.5 leading-4 text-default dark:bg-gray-500/30;

  &.is-small {
    @apply h-4 min-w-4 px-[5px] py-0;
  }

  &.is-selected {
    @apply bg-blue-500 text-blue-50 dark:text-white;
  }
}

.has-badge:hover .badge {
  @apply bg-gray-200/50 dark:bg-gray-200/50;

  &.is-selected {
    @apply bg-blue-500;
  }
}
