.fv-insider-row {
  @apply relative;

  td {
    @apply border-t first:border-l last:border-r;
  }

  &:first-of-type td {
    @apply first:rounded-tl-md last:rounded-tr-md;
  }
  &:last-of-type td {
    @apply border-b first:rounded-bl-md last:rounded-br-md;
  }

  td[align='right'],
  td.text-right {
    @apply tabular-nums;
  }

  /* hack to cover up borders intersection */
  &:not(:first-child, :hover) td {
    @apply first:before:absolute first:before:left-0 first:before:top-0 first:before:h-px first:before:w-px dark:first:before:bg-gray-600;
    @apply first:after:absolute first:after:right-0 first:after:top-0 first:after:h-px first:after:w-px dark:first:after:bg-gray-600;
  }

  &.is-option td {
    @apply bg-white dark:bg-gray-800;
    @apply border-t-gray-100 dark:border-t-gray-700;
  }

  &.is-buy-1 td {
    @apply border-t-green-100 bg-green-50 dark:bg-green-400 dark:bg-opacity-[0.15] dark:text-green-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.green.400) 35%);
    }
  }

  &.is-buy-2 td {
    @apply border-t-green-200 bg-green-100 dark:bg-green-400 dark:bg-opacity-40 dark:text-green-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.green.400) 65%);
    }
  }

  &.is-sale-1 td {
    @apply border-t-red-100 bg-red-50 dark:bg-red-400 dark:bg-opacity-[0.15] dark:text-red-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.red.400) 35%);
    }
  }

  &.is-sale-2 td {
    @apply border-t-red-200 bg-red-100 dark:bg-red-400 dark:bg-opacity-40 dark:text-red-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.red.400) 65%);
    }
  }

  &.is-proposedSale-1 td {
    @apply border-t-orange-100 bg-orange-50 dark:bg-tangerine-400 dark:bg-opacity-[0.15] dark:text-tangerine-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.tangerine.400) 35%);
    }
  }

  &.is-proposedSale-2 td {
    @apply border-t-orange-200 bg-orange-100 dark:bg-tangerine-400 dark:bg-opacity-40 dark:text-tangerine-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.tangerine.400) 65%);
    }
  }

  &.is-buy-1 + .is-option td {
    @apply border-t-green-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.green.400) 35%);
    }
  }

  &.is-buy-2 + .is-option td,
  &.is-buy-2 + .is-buy-1 td {
    @apply border-t-green-200 dark:text-green-100;
    @apply dark:text-green-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.green.400) 65%);
    }
  }

  &.is-sale-1 + .is-option td {
    @apply border-t-red-100;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.red.400) 35%);
    }
  }

  &.is-sale-2 + .is-option td,
  &.is-sale-2 + .is-sale-1 td {
    @apply border-t-red-200;
    .dark & {
      border-top-color: color-mix(in srgb, theme(colors.gray.800), theme(colors.red.400) 65%);
    }
  }

  td,
  &:first-of-type td {
    @apply border-gray-100 dark:border-gray-600;
  }

  &.is-option:hover,
  &.is-buy-1:hover,
  &.is-buy-2:hover,
  &.is-sale-1:hover,
  &.is-sale-2:hover,
  &.is-proposedSale-1:hover,
  &.is-proposedSale-2:hover {
    td {
      @apply border-accent bg-accent;
    }
    & + tr td {
      @apply border-t-accent;
      .dark & {
        @apply border-t-accent;
      }
    }
  }
}
