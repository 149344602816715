body.is-groups {
  @apply text-2xs;

  .fv-bar-chart {
    .rect {
      .value,
      .label {
        @apply leading-5;
      }
    }
  }
}

.groups_table {
  th {
    @apply leading-[0.9rem];
  }

  th,
  td {
    @apply px-[3px] first:pl-1.5 last:pr-1.5;
  }
}
.groups_index-cell {
  @apply w-9;
}

.groups_charts-screener-link {
  @apply inline-block rounded border border-transparent p-1.5 hover:border-blue-400;
}

.groups_settings-button {
  &.is-open {
    @apply bg-gray-200 dark:bg-gray-600;

    svg {
      @apply rotate-180;
    }
  }
}

.groups_spectrum-wrapper {
  @apply py-5 text-center;
}
.groups_spectrum-image {
  @apply inline-block rounded-md dark:border dark:border-gray-600;
}

.screener-groups_table-filter {
  @apply border-x border-primary text-2xs text-gray-600 dark:text-gray-300;

  td {
    @apply h-9;
  }
}
.screener-groups_filter-label {
  @apply px-1 leading-none font-medium;
}
.screener-groups_settings-table-wrapper {
  @apply relative border-l border-r border-primary p-1.5;
}
.screener-groups_settings-table {
  td {
    @apply h-7.5 text-2xs leading-[1.2] odd:pr-1 odd:text-right;

    &.is-buttons-cell {
      @apply h-6 p-0 pb-1;
    }
  }

  &.is-custom td {
    @apply odd:text-left even:pr-2;
  }

  &.is-dimmer-text {
    @apply text-gray-700 dark:text-gray-200;
  }
}
