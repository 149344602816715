.myaccount-navigation {
  @apply h-9 min-w-[1000px] border-b border-gray-100 dark:border-gray-700;
}
.myaccount-navigation_link {
  @apply h-9 whitespace-nowrap border-b-2 border-b-transparent p-2 text-sm font-medium hover:text-blue-500 dark:hover:text-blue-300;

  &.is-active {
    @apply border-b-blue-400 text-blue-500 font-medium dark:text-blue-300;
  }
}
