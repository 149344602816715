.screener-view-switch {
  @apply border-none text-2xs font-medium;

  tr {
    @apply flex flex-row;
  }

  .screener-view-button {
    @apply -mx-px flex h-7 grow items-stretch border border-primary bg-gray-50 p-0 first:ml-0 first:rounded-bl-md last:mr-0 last:rounded-br-md hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600;

    &.is-active {
      @apply relative border-blue-500 bg-blue-50 dark:border-blue-400 dark:bg-gray-700;
    }

    /* is-screener override is necessary because this component is used on other pages too e.g. Groups */
    &.is-screener {
      /* Same width buttons look better, but won't fit on small screens */
      /* 100 / 17 views = 5.88 */
      min-width: 5%;
      @apply 3xl:w-[5.88%] 3xl:min-w-max;
      a {
        /* Give a bit more space to "Performance" */
        @apply 3xl:px-2;
      }
    }

    a {
      @apply flex grow items-center justify-center;
    }
  }
}
