@import './pagination.css';

body.is-screener {
  @apply text-2xs;

  /** Stats chart border - this won't be needed after quote header merge */
  #chart .pane {
    @apply border-none;
  }

  .screener-groups_table-filter,
  .screener-groups_settings-table-wrapper {
    @apply bg-secondary dark:bg-primary;
  }
  .screener-groups_settings-table-wrapper {
    @apply py-[3px];
  }
  .screener-groups_table-filter {
    @apply text-gray-700 dark:text-gray-200;
  }
  .screener-groups_filter-label {
    @apply font-normal;
  }
}

.screener_profile {
  @apply mt-2 rounded-md border border-primary p-2 text-2xs leading-4 text-muted;
}

.screener_table {
  a,
  th {
    padding: 0 3px;
  }

  a {
    @apply block w-full whitespace-nowrap py-0.5;
  }

  th {
    @apply leading-[0.9rem] first:pl-1.5 last:pr-1.5;
  }

  td {
    @apply p-0;

    &:first-child a {
      @apply pl-1.5;
    }
    &:last-child a {
      @apply pr-1.5;
    }
  }
}

.screener_filter-tabs-section {
  @apply relative flex items-center border-x border-x-primary bg-secondary dark:bg-transparent;
}
.screener_filter-tabs-filler {
  @apply h-px border-b border-primary;
}
.screener_filter-tabs-wrapper {
  @apply flex justify-center bg-secondary dark:bg-primary;
}
.screener_filter-tab {
  @apply relative -ml-px flex h-6 cursor-pointer items-center justify-center border border-gray-200 bg-primary px-2.5 text-muted font-medium first:m-0 first:rounded-l-md last:rounded-r-md dark:border-gray-600 dark:bg-secondary;
  @apply hover:z-20 hover:border-gray-400 hover:bg-secondary hover:text-default dark:hover:border-gray-400;

  &.is-selected {
    @apply z-10 border-blue-400 bg-blue-50 text-default dark:border-blue-400 dark:bg-blue-700;
  }
}

.screener_tickers {
  @apply w-full space-y-0.5 rounded-md bg-gray-600 text-center align-top leading-[normal] text-white dark:bg-transparent;

  span {
    @apply inline-block rounded-sm py-0.5;
  }
}

.screener_custom-add-button {
  @apply absolute right-1.5 top-1/2 -mt-2.5 h-5 w-5 min-w-0 rounded bg-blue-100 p-0 text-blue-700 hover:bg-blue-200 dark:bg-blue-800 dark:text-blue-100 dark:hover:bg-blue-900;
}
.screener_custom-clear-button {
  @apply flex h-4 w-4 min-w-0 px-0 2xl:h-6 2xl:w-6;
}
.screener_button {
  &.is-active {
    @apply dark:bg-blue-700;
  }

  &.is-open {
    &:not(.is-active) {
      @apply bg-gray-200 dark:bg-gray-600;
    }

    svg {
      @apply rotate-180;
    }
  }

  &.is-arrow {
    @apply h-6 w-6 min-w-0 px-0 align-middle;
  }

  &.is-disabled {
    @apply pointer-events-none;
  }
}

.screener_custom-input-table {
  tr td {
    @apply h-full last:pl-1 last:pr-0;
  }

  &.is-modal td {
    @apply last:pl-0;
  }

  &.is-range td:nth-child(odd) {
    @apply pr-0;
  }
}
.screener_custom-input {
  @apply text-default;

  &.is-prefix:not(:empty) {
    @apply pr-0.5 2xl:pr-1;
  }

  &.is-suffix:not(:empty) {
    @apply pl-0.5 2xl:pl-1;
  }

  &.is-delimiter {
    @apply px-px 2xl:px-0.5;
  }
}

/** Total, Autorefresh, page select **/
.count-text {
  @apply text-gray-500;
}

.screener_snapshot-table-wrapper {
  @apply my-1 ml-2 rounded-md border border-primary p-2 text-muted-2 dark:text-gray-400;
}
.screener_snapshot-table-header {
  @apply min-w-[335px];

  td {
    @apply px-1 py-px text-sm;
  }

  a {
    @apply text-link hover:text-blue-600 dark:hover:text-blue-300;
  }

  b {
    @apply font-normal;
  }
}
.screener_snapshot-table-body {
  @apply mt-2 whitespace-nowrap text-2xs;

  td {
    @apply px-1 py-0;
  }

  tr:not(:last-child) td {
    @apply h-5 border-b border-secondary;
  }

  b {
    @apply text-default font-medium;
  }
}

/**
 * Filter inputs have darker border, but they override highlight styles, so we
 * need to add :not selector
 */
.filter-table-filters .fv-input:not(.is-highlight),
.filter-table-filters .fv-select:not(.is-highlight) {
  @apply border-gray-200 hover:border-gray-400 dark:border-primary dark:hover:border-gray-400;
}
