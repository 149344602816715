.react-calendar {
  @apply rounded border-gray-100 bg-white font-sans dark:border-gray-600 dark:bg-gray-800;

  .react-calendar__navigation {
    @apply border-t-0;
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    @apply border-t;
  }

  .react-calendar__navigation__arrow {
    &:first-child {
      @apply rounded-tl;
    }

    &:last-child {
      @apply rounded-tr;
    }
  }

  .react-calendar__navigation__label,
  button.react-calendar__tile--active {
    @apply font-semibold;
  }

  .react-calendar__navigation,
  .react-calendar__tile {
    @apply text-gray-600;

    &.react-calendar__month-view__days__day--neighboringMonth {
      @apply text-gray-400;
    }
  }

  .react-calendar__tile {
    &:last-child {
      @apply rounded-br;
    }

    &:nth-last-child(7) {
      @apply rounded-bl;
    }
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    @apply font-medium;
  }
}
