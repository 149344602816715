.header {
  .logo {
    @apply inline-flex w-auto items-center justify-start bg-none;
  }

  /* Free overrides */
  body:not(.is-elite) & {
    @apply min-h-[96px] dark:bg-[#363a46];

    .search input {
      @apply dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200;

      &.is-focused {
        @apply bg-white text-gray-500;
      }
    }
  }
}
