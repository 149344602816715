.alert-notification {
  @apply pt-3;

  /* tags referenced directly because it comes from db withoud any classnames */
  h1,
  h2 {
    @apply text-gray-600 font-bold dark:text-gray-200;
  }
  h1 {
    @apply mb-3 mt-5 text-3xl;
  }
  h2 {
    @apply mb-2 mt-4 text-2xl;
  }
  a {
    @apply block overflow-hidden text-ellipsis whitespace-nowrap text-blue-500 hover:underline dark:text-blue-300;
  }
}
