body.is-futures {
  .fv-container {
    @apply min-w-0;

    @media (max-width: 1024px) {
      padding: 0 20px;
    }
  }
}

.futures {
  @apply mx-auto my-0 max-w-[1282px];
}
