body.is-help {
  p {
    @apply my-2;

    &.help-info {
      @apply my-0;
    }
  }

  img {
    @apply inline-block;
  }

  b {
    @apply font-medium;
  }

  .content-header {
    @apply mx-0 my-5;
  }

  hr {
    @apply hidden;
  }

  .faq_answer p {
    @apply my-0;
  }
}

.help_image-wrapper {
  @apply inline-block rounded-md p-3 dark:bg-gray-600;

  img {
    @apply rounded;
  }

  + .help_image-wrapper {
    @apply mt-3;
  }
}
.help_video-wrapper {
  @apply rounded-md p-3 dark:bg-gray-600;

  iframe {
    @apply rounded;
  }
}

.help-technical {
  @apply flex justify-center;
}

.help-menu {
  @apply sticky h-9 border-b border-secondary bg-primary sticky-0;
}
.help-menu_list {
  @apply flex h-full justify-center;
}
.help-menu_link {
  @apply block h-9 cursor-pointer border-b-2 border-b-transparent p-2 text-sm capitalize transition-colors font-medium hover:text-link;

  &.is-active {
    @apply border-b-blue-400 text-link font-medium;
  }
}

.help-submenu {
  @apply mr-11 mt-6;
}
.help-submenu_list {
  @apply sticky space-y-0.5 sticky-15;
}
.help-submenu_link {
  @apply flex h-8 cursor-pointer items-center whitespace-nowrap rounded-md border border-transparent p-2 text-sm leading-none text-gray-600 font-medium hover:bg-gray-50 hover:text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-50;

  &.is-active {
    @apply border-blue-400 bg-blue-50 text-gray-800 hover:bg-blue-50 dark:bg-gray-700 dark:text-white dark:hover:text-white;
  }
}

.help-small {
  @apply text-sm text-muted;
}

.help-group,
.help-hlite {
  @apply space-y-0.5;
}

.faq {
  @apply space-y-8;
}

.faq_section {
  @apply space-y-2;
}

.faq_question {
  @apply cursor-pointer rounded-md border border-primary bg-no-repeat p-4 pr-14 hover:bg-gray-50 dark:hover:bg-gray-700;

  --background-openned: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="%23A3A8B9" d="M5.5 13a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5h13c.28 0 .5.22.5.5v1a.5.5 0 0 1-.5.5h-13Z"/></svg>');
  --background-closed: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="%23A3A8B9" d="M19 11.5v1a.5.5 0 0 1-.5.5H13v5.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V13H5.5a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5H11V5.5c0-.28.22-.5.5-.5h1c.28 0 .5.22.5.5V11h5.5c.28 0 .5.22.5.5Z"/></svg>');
  background-image: var(--background-closed);
  background-size: 24px 24px;
  background-position: top 16px right 16px;

  &.is-open {
    background-image: var(--background-openned);
    @apply space-y-4;
  }
}
.faq_question-title {
  @apply font-medium;
}
.faq_answer {
  transition: all 400ms;
  @apply h-0 space-y-2 overflow-hidden text-muted;
}

.faq_well {
  @apply space-y-4 rounded-md border border-primary bg-gray-50 p-4 text-center dark:bg-gray-700;
}
.faq_well_title {
  @apply text-xl text-muted font-bold;
}
