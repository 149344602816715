.fv-input,
.fv-select {
  @apply h-6 appearance-none rounded-md border border-primary bg-primary px-1.5 font-sans text-2xs text-default outline-none focus:border-blue-400 hover:border-gray-300 dark:text-gray-50 dark:hover:border-gray-400;

  &.is-readonly,
  &:disabled {
    @apply cursor-not-allowed border-secondary bg-gray-50 text-gray-500 hover:border-secondary dark:bg-gray-700 dark:text-gray-400;
  }

  &.is-large {
    @apply h-9 text-sm;
  }
}

.fv-input {
  &.is-highlight {
    @apply border-blue-400 bg-blue-50 font-medium hover:border-blue-400 dark:bg-blue-700;
  }
  &.is-error,
  /* Admin panel validation selector */
  &.input-validation-error {
    @apply border-red-400;
  }
}

.fv-select {
  @apply bg-right bg-no-repeat pr-6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='16' %3E%3Cpath d='M6.2 10.5a.7.7 0 0 1 0-1l.3-.3L7 9h10c.2 0 .4 0 .5.2l.3.3a.7.7 0 0 1 0 1l-5.3 5.3a.7.7 0 0 1-1 0l-5.3-5.3Z' fill='%23A3A8B9' %3E%3C/path%3E%3C/svg%3E");

  option {
    @apply bg-white dark:bg-gray-800;
  }

  &:hover,
  .dark & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='16' %3E%3Cpath d='M6.2 10.5a.7.7 0 0 1 0-1l.3-.3L7 9h10c.2 0 .4 0 .5.2l.3.3a.7.7 0 0 1 0 1l-5.3 5.3a.7.7 0 0 1-1 0l-5.3-5.3Z' fill='%23868EA5' %3E%3C/path%3E%3C/svg%3E");
  }

  &.is-highlight {
    @apply border-blue-400 bg-blue-50 font-medium hover:border-blue-500 dark:bg-blue-700 dark:hover:border-blue-300;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='16' %3E%3Cpath d='M6.2 10.5a.7.7 0 0 1 0-1l.3-.3L7 9h10c.2 0 .4 0 .5.2l.3.3a.7.7 0 0 1 0 1l-5.3 5.3a.7.7 0 0 1-1 0l-5.3-5.3Z' fill='%23306DCA' %3E%3C/path%3E%3C/svg%3E");

    .dark & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='16' %3E%3Cpath d='M6.2 10.5a.7.7 0 0 1 0-1l.3-.3L7 9h10c.2 0 .4 0 .5.2l.3.3a.7.7 0 0 1 0 1l-5.3 5.3a.7.7 0 0 1-1 0l-5.3-5.3Z' fill='%232F91EF' %3E%3C/path%3E%3C/svg%3E");
    }
  }

  &[multiple] {
    @apply h-auto min-h-20 bg-none py-2 dark:bg-none;

    & > option {
      @apply mb-px rounded px-2 py-1;

      &:checked {
        @apply bg-blue-400 text-white dark:bg-blue-500 dark:text-default;
      }
    }
  }
}

/** needs to be also on input an button because tailwind override background to transparent */
.fv-button,
input.fv-button,
button.fv-button {
  /* button shape */
  @apply inline-flex items-center justify-center rounded-md border-none font-sans outline-none ring-gray-200 font-medium focus-visible:ring-2 dark:ring-gray-500;
  /* button gray */
  @apply bg-gray-200/30 text-gray-900 hover:bg-gray-200/50 dark:bg-gray-500/30 dark:text-gray-50 dark:hover:bg-gray-500/50;
  /* button default */
  @apply h-7 min-w-7 cursor-pointer px-2 text-2xs;

  &.is-readonly,
  &:disabled {
    @apply cursor-not-allowed;
    /* disabled light */
    @apply bg-gray-200/30 text-gray-400 hover:bg-gray-50 hover:text-gray-400;
    /* disabled dark */
    @apply dark:bg-gray-500/30 dark:text-gray-300 dark:hover:bg-gray-500/30 dark:hover:text-gray-300;
  }

  &.is-xsmall {
    @apply h-5 min-w-5 px-1;
  }
  &.is-small {
    @apply h-6 min-w-6 px-1.5;
  }
  &.is-medium {
    @apply h-8 min-w-8 px-2.5 text-sm;
  }
  &.is-large {
    @apply h-9 min-w-9 px-3 text-base;
  }

  &.is-square {
    @apply p-0;
  }

  &.is-left-align {
    @apply justify-start;
  }

  &.is-border {
    @apply border border-solid border-transparent;
  }

  &.is-primary {
    @apply bg-blue-400 text-white hover:bg-blue-500;
  }
  &.is-chip-transparent {
    @apply border-transparent bg-transparent text-muted hover:bg-gray-200/30 hover:text-default dark:hover:bg-gray-500/30;
  }
  &.is-active {
    @apply border-blue-400 bg-blue-50 text-default hover:border-blue-500 hover:bg-blue-50 dark:bg-gray-500/30 dark:hover:border-blue-300;
  }
  &.is-link {
    @apply bg-transparent text-blue-500 hover:bg-transparent hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-200;
  }
}

/**
 * Checkbox
 * <label class="checkbox-wrapper">
 *   <input type="checkbox">
 *   <span />
 * </label>
 **/
.checkbox-wrapper {
  @apply relative cursor-pointer;

  input {
    @apply relative block h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-200 bg-white ring-gray-300 checked:border-blue-400 checked:bg-blue-400 focus:outline-none focus-visible:ring-2 dark:border-gray-600 dark:bg-gray-800 dark:checked:border-blue-400 dark:checked:bg-blue-400;

    &.is-readonly,
    &:disabled {
      @apply border-primary bg-gray-100 checked:border-gray-400 checked:bg-gray-400 hover:border-primary checked:hover:border-gray-400 checked:hover:bg-gray-400 dark:bg-gray-600 dark:checked:border-gray-600 dark:checked:bg-gray-600;
    }

    &:disabled {
      @apply cursor-default;
    }

    &.is-readonly {
      @apply cursor-not-allowed;
    }

    &:checked + span {
      @apply pointer-events-none absolute bottom-0 left-0 right-0 top-0 block;
      content: '';
      background: no-repeat center
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M17.74 5.11a1.51 1.51 0 0 1 2.1 0l.02.02.7.7a1.5 1.5 0 0 1 0 2.13l-10.6 10.6a1.5 1.5 0 0 1-2.13 0l-5.38-5.39a1.5 1.5 0 0 1 0-2.13l.7-.7a1.5 1.5 0 0 1 2.12 0l3.62 3.63 8.85-8.86Z' /%3E%3C/svg%3E");
      background-size: 12px 12px;
    }
  }

  &:hover input {
    @apply border-gray-400 checked:border-blue-500 checked:bg-blue-500 dark:checked:border-blue-500 dark:disabled:border-gray-600;
  }
}

/**
 * Switch example
 * <label class="switch">
 *   <span>Label</span>
 *   <input type="checkbox" />
 *   <span class="slider"></span>
 * </label>
 **/
.switch {
  @apply flex items-center space-x-1;

  input {
    @apply absolute opacity-0;

    &:checked + .slider:before {
      @apply translate-x-3.5 bg-blue-400;
    }

    &:focus:not(:hover) + .slider {
      @apply border-gray-200 ring-1 ring-gray-100 dark:border-gray-600 dark:ring-gray-600;
    }

    &:disabled + .slider {
      @apply border-gray-100 bg-gray-100 before:bg-gray-400 dark:border-gray-600 dark:bg-gray-600;
    }
    &:checked:disabled + .slider {
      @apply before:bg-white dark:before:bg-gray-50;
    }
  }

  .slider {
    @apply relative inline-block h-4.5 w-8 rounded-full border border-gray-200 bg-primary dark:border-gray-600;

    &:before {
      @apply absolute left-0.5 top-0.5 h-3 w-3 rounded-full bg-gray-400 content-[''];
      transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  &:hover {
    .slider {
      @apply border-gray-400 dark:border-gray-400 dark:before:bg-gray-300;
    }

    input:checked:not(:disabled) + .slider:before {
      @apply bg-blue-500 dark:bg-blue-300;
    }
  }
}
