body.is-crypto {
  .chart-menu {
    @apply flex justify-center;
  }
  .chart-menu_charts {
    @apply max-w-[79rem] flex-wrap;

    .chart-menu_chart-preview {
      @apply float-none w-[102px];
    }
  }
}
