#chart {
  @apply mb-4 rounded-md border border-primary bg-primary;

  .chart {
    @apply overflow-hidden rounded-md;
  }

  .pane {
    @apply last:overflow-hidden last:rounded-b-md last:after:hidden;

    &:after {
      content: '';
      @apply absolute inset-x-1.5 bottom-0 block h-0 border-b border-solid border-secondary;
    }
  }

  .chart-idea-note_wrapper {
    @apply px-1.5 pb-1.5;

    textarea {
      @apply min-h-6.5;
    }

    /**
      * This logic would require lot more JS than one liner css
      */
    textarea:empty:not(:focus) {
      @apply max-h-6 max-w-[12.75rem];
    }
  }
}

.charts-container {
  @apply flex flex-wrap justify-center gap-0.5;
}
.charts-gal-link {
  @apply rounded border border-transparent p-1.5 hover:border-blue-400;
}

.chart-menu_charts {
  @apply flex items-center justify-center space-x-2 pt-5;

  .chart-menu_chart-preview {
    @apply rounded-md border border-transparent p-1.5 pt-0.5 text-3xs uppercase text-muted font-semibold hover:bg-gray-100 dark:hover:bg-gray-800;

    &.is-active {
      @apply border-blue-400 bg-blue-50 text-default dark:bg-gray-700;

      .sparkline {
        @apply dark:stroke-white;
      }
    }

    .sparkline {
      @apply fill-none stroke-gray-600 dark:stroke-gray-200;
    }
  }
}
