.chart-tooltip {
  .tooltip_hdr,
  .tooltip_bdy,
  .tooltip_tab {
    @apply border-0 bg-gray-700 text-2xs leading-normal text-gray-50 dark:bg-gray-300 dark:text-gray-700;
  }

  .tooltip_hdr {
    @apply rounded-t-md px-2 pt-1.5 font-medium;
  }

  .tooltip_bdy {
    @apply rounded-b-md px-2 pb-1.5;
  }

  .tabchrtbdy,
  .hoverchart {
    @apply overflow-hidden rounded-md border border-gray-100 bg-white text-2xs shadow-modal dark:border-gray-600 dark:bg-gray-800 dark:shadow-modal-dark;

    img {
      @apply m-2;
    }
  }

  .hoverchart {
    div {
      @apply w-0 min-w-full;
    }

    b {
      @apply block;
    }
  }

  .hoverchart div,
  .tabchrtbdy {
    @apply bg-gray-50 p-2.5 py-1.5 text-2xs leading-normal text-gray-500 dark:bg-gray-700 dark:text-gray-400;
    background-image: none;

    b {
      @apply text-gray-900 font-medium dark:text-gray-50;
    }

    span {
      @apply px-0.5 text-gray-200 dark:text-gray-600;
    }
  }
}

.tooltip_short_bdy {
  @apply rounded border-0 bg-gray-700 px-2 py-1.5 text-2xs leading-4 text-gray-50 dark:bg-gray-200 dark:text-gray-700;
}
