body.is-calendar {
  .calendar_table {
    @apply mt-5 first-of-type:mt-2;
  }
}

.calendar_table {
  tr td,
  tr th {
    @apply px-0;
  }

  tr td:last-child,
  tr th:last-child {
    padding-right: 0.375rem;
  }
}
.calendar_row {
  &.is-now td {
    @apply bg-yellow-100 dark:bg-yellow-700;
  }
  &.is-future td {
    @apply bg-yellow-50 dark:bg-yellow-700 dark:bg-opacity-[0.35];
  }
}
