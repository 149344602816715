.screener_pagination {
  @apply flex items-center justify-center py-0.5 text-base text-gray-200 font-bold dark:text-gray-600;

  b {
    @apply font-normal;
  }
}
.screener-pages {
  @apply flex h-6 min-w-6 items-center justify-center rounded-md border border-transparent px-1.5 py-0 text-2xs leading-6 text-link font-normal;

  &:hover {
    @apply bg-gray-50 decoration-transparent dark:bg-gray-600 dark:text-white;
  }

  &.is-selected {
    @apply border-blue-400 bg-blue-50 text-gray-900 dark:bg-gray-700 dark:text-white;
  }

  &.is-next,
  &.is-prev {
    @apply px-0;
  }
}
