/** Quote notice **/
/* #quote-notice { */
.notice-message {
  @apply my-2.5 flex justify-center font-medium;
}
.notice-message_content {
  @apply relative flex w-[800px] rounded-md border border-violet-600 bg-violet-600 p-2 pr-10 text-violet-200 dark:border-violet-500 dark:bg-violet-800;
}
.notice-message_text {
  @apply ml-2 grow text-left text-sm;

  a {
    @apply text-white underline hover:no-underline;
  }
}
.notice-message_close {
  @apply absolute right-2 top-1.5 flex h-6 w-6 items-center justify-center rounded-full bg-violet-700;
}
