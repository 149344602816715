@import '../header.css';

.header-container img {
  @apply max-w-none;
}

.navbar,
.header-container {
  font-family: 'Lato', sans-serif !important;
  font-size: 14px;
}

.fv-container {
  width: 100%;
  min-width: 1000px;
  max-width: 1425px;
  margin: 0 auto;
  padding: 0 20px;

  html.is-responsive & {
    min-width: auto;
  }
  body.is-wide & {
    max-width: 1575px;
  }

  &.is-wide {
    max-width: 1615px;
  }
  &.is-tight {
    @apply w-[95%] p-0;
  }
}

.fixed-width-medium {
  margin: 0 auto;
  width: 650px;
}

.fixed-width-small {
  margin: 0 auto;
  width: 366px;

  html.is-responsive & {
    width: 100%;
    max-width: 366px;
  }
}

.center-text {
  text-align: center;
}

.news-link-source {
  margin-right: 4px;
  font-size: 9px;
}

.news-link-source,
.news-link-right {
  color: #aa6dc0;
}

.signal-big-screen {
  display: inline;
}

.signal-small-screen {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .signal-big-screen {
    display: none;
  }

  .signal-small-screen {
    display: inline;
  }
}

/* This can be replaced by tw hidden class when css order will be fixed */
.hide {
  display: none !important;
}

.perf-chart-container {
  @apply flex justify-center;
}
