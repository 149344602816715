.fv-label {
  @apply rounded-sm border border-transparent bg-transparent px-0.5;

  &.is-small {
    @apply inline-flex h-4 items-center text-2xs;
  }

  &.is-neutral {
    @apply text-gray-800 dark:text-gray-100;
  }

  &.is-negative-50 {
    @apply text-red-500 dark:text-red-300;
  }

  &.is-negative-100 {
    @apply border-red-50 bg-red-50 text-red-600 dark:border-red-700 dark:bg-red-700 dark:text-white;
  }

  &.is-negative-200 {
    @apply border-red-100 bg-red-100 text-red-600 dark:border-red-500 dark:bg-red-500 dark:text-white;
  }

  &.is-positive-50 {
    @apply text-green-500 dark:text-green-300;
  }

  &.is-positive-100 {
    @apply border-green-50 bg-green-50 text-green-600 dark:border-green-700 dark:bg-green-700 dark:text-white;
  }

  &.is-positive-200 {
    @apply border-green-100 bg-green-100 text-green-700 dark:border-green-500 dark:bg-green-500 dark:text-white;
  }

  &.is-yellow {
    @apply border border-yellow-200 bg-yellow-100 text-default dark:border-yellow-500 dark:bg-yellow-700 dark:text-white;
  }

  &.is-opaque {
    @apply border-[theme(backgroundColor.primary)];

    &.is-neutral {
      @apply bg-gray-50 text-gray-600 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200;
    }

    &.is-negative-50 {
      @apply bg-red-50 dark:border-red-700 dark:bg-red-700 dark:text-red-300;
    }

    &.is-negative-100 {
      @apply bg-red-100 dark:border-red-600 dark:bg-red-600;
    }

    &.is-negative-200 {
      @apply bg-red-200 text-red-700 dark:bg-red-500 dark:text-white;
    }

    &.is-positive-50 {
      @apply bg-green-50 dark:border-green-700 dark:bg-green-700 dark:text-green-300;
    }

    &.is-positive-100 {
      @apply bg-green-100 text-green-500 dark:border-green-600 dark:bg-green-600 dark:text-green-200;
    }

    &.is-positive-200 {
      @apply bg-green-200 dark:bg-green-500 dark:text-white;
    }
  }
}

.has-label:hover .fv-label,
.fv-label.is-active {
  &.is-neutral {
    @apply border-bg-primary bg-primary;
  }

  &.is-negative-50 {
    @apply border-bg-primary bg-primary;
  }

  &.is-negative-100 {
    @apply border-red-300 dark:border-red-600;
  }

  &.is-negative-200 {
    @apply border-red-300 text-red-600 dark:border-red-500 dark:text-white;
  }

  &.is-positive-50 {
    @apply border-bg-primary bg-primary;
  }

  &.is-positive-100 {
    @apply border-green-300 dark:border-green-600;
  }

  &.is-positive-200 {
    @apply border-green-300 text-green-700 dark:border-green-500 dark:text-white;
  }
}
