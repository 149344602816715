.bar-chart-wrapper {
  @apply rounded-md border border-primary px-4 leading-5;
}

.fv-bar-chart {
  @apply leading-5;

  h1 {
    @apply my-4 px-0 text-xl uppercase text-muted font-bold;
  }

  .rect {
    @apply cursor-pointer rounded-sm;

    &.is-inactive {
      @apply bg-gray-100 opacity-100 dark:bg-gray-700;
    }

    .value,
    .label {
      @apply absolute -mt-0.5 text-2xs leading-4 font-medium;
      text-shadow: none;
      width: auto;
    }

    .value {
      @apply !left-full px-0.5 !text-muted;
    }

    .label {
      @apply !right-full left-auto whitespace-nowrap px-1 text-gray-500 dark:text-gray-400;
    }
  }

  .bar-chart_tick {
    @apply h-full w-px border-l border-dashed border-secondary first:border-l-none;

    div {
      @apply absolute top-full -ml-2.5 w-5 text-2xs text-gray-500 opacity-100 font-medium dark:text-gray-400;
    }
  }

  .bar-chart_top,
  .bar-chart_bottom {
    @apply hidden;
  }

  &.is-horizontal {
    .bar-chart_top,
    .bar-chart_bottom {
      @apply absolute block border-dashed border-secondary;
    }

    .bar-chart_top {
      @apply -top-0.5 border-t;
    }
    .bar-chart_bottom {
      @apply -bottom-0.5 border-b;
    }
  }

  &.is-wrapper {
    @apply rounded-md border border-primary px-4;
  }
}

.fv-vertical-bar-chart {
  @apply ml-0 mr-16;

  h1 {
    @apply my-4 px-0 text-center text-xl uppercase text-muted font-bold;
  }

  .rect {
    @apply cursor-default;
    transition: all 0.5s ease-in-out;

    .value,
    .label {
      @apply absolute w-full text-center text-2xs font-medium;
    }

    .value {
      @apply leading-none text-muted;
    }

    .label {
      @apply text-gray-50;
    }
  }

  .bar-chart_tick {
    @apply h-px w-full border-b border-dashed border-secondary;

    div {
      @apply absolute -top-[10px] left-full ml-2 w-14 text-2xs text-gray-500 opacity-100 font-medium dark:text-gray-400;
    }
  }
}
