.market-stats {
  @apply mb-3 w-57.5 max-w-[300px] cursor-default rounded-md border border-primary p-1.5 text-3xs leading-[0.875rem];
}
.market-stats_labels {
  @apply relative h-7.5 text-center text-muted-2;

  .market-stats_labels_left {
    @apply absolute left-0.5 top-0 text-left text-green-500 font-medium dark:text-green-300;
  }
  .market-stats_labels_right {
    @apply absolute right-0.5 top-0 text-right text-red-500 font-medium dark:text-red-300;
  }
}
.market-stats_bar {
  @apply relative flex h-1.5 justify-between;

  .market-stats_bar_left-bar,
  .market-stats_bar_right-bar {
    @apply relative h-1.5;
  }

  .market-stats_bar_center-bar {
    @apply bg-gray-100 dark:bg-gray-700;
  }

  .market-stats_bar_left-bar {
    @apply rounded-l bg-green-400;
  }

  .market-stats_bar_right-bar {
    @apply rounded-r bg-red-400;
  }
}

.market-sentiment {
  @apply h-auto min-h-12.5 w-57.5 max-w-[300px] rounded-md border border-primary p-1;
}
.market-sentiment_bull,
.market-sentiment_bear {
  @apply mt-0.5 block h-3.5 w-15 rounded-sm text-3xs uppercase leading-[0.75rem] text-white font-medium;

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }
}
.market-sentiment_bull {
  @apply bg-green-500;
}
.market-sentiment_bear {
  @apply bg-red-500;
}
