div.footer {
  @apply bg-gray-50 !py-8 text-center text-2xs text-gray-500 dark:bg-gray-900;

  a {
    @apply text-2xs capitalize;
  }

  .footer_links {
    @apply mb-1;
  }

  .footer_dot {
    @apply px-0.5 text-2xs text-gray-100 dark:text-gray-700;
  }
}
