body.is-portfolio {
  .content {
    @apply flex grow flex-col;

    #root {
      @apply flex flex-1 flex-col;
    }
  }
}

.portfolio-table-cell {
  @apply border-secondary bg-primary;

  &.is-first {
    @apply border-l;
  }

  &.is-last {
    @apply border-r;
  }

  &.has-divider:not(.is-first-row &) {
    /* We always want the border to be gray */
    @apply border-t !border-t-secondary border-t-dashed;
  }

  .is-group-open & {
    @apply border-accent;
  }

  .is-group-remove &,
  .is-group-remove .is-first-row.is-hoverable:hover & {
    @apply border-red-400 dark:border-red-400;
  }

  .is-rounded.is-first-row &,
  .is-first-group > .is-first-row & {
    &.is-first {
      @apply rounded-tl-md;
    }

    &.is-last {
      @apply rounded-tr-md;
    }
  }

  .is-first-row & {
    @apply border-t;
  }

  .is-rounded.is-last-row &,
  .is-last-group > .is-last-row & {
    @apply border-b border-b-solid;

    &.is-first {
      @apply rounded-bl-md;
    }

    &.is-last {
      @apply rounded-br-md;
    }
  }

  .is-first-row.is-hoverable:hover &,
  .portfolio-trades-table-row:hover & {
    @apply border-accent bg-accent;
  }
}

.is-group-open,
.portfolio-ticker-group:hover {
  & + .portfolio-ticker-group > .is-first-row.is-hoverable .portfolio-table-cell {
    @apply border-t-accent;
  }
}

.is-group-remove {
  & + .portfolio-ticker-group,
  & {
    & .is-first-row.is-hoverable .portfolio-table-cell {
      @apply !border-t-red-400 dark:!border-t-red-400;
    }
  }
}
