body.is-quote {
  .fv-container {
    @apply min-w-[1009px] px-2 xl:px-4;
  }

  .fullview-links {
    @apply text-2xs text-muted-3;
  }

  .screener_snapshot-table-wrapper {
    @apply m-0 bg-primary p-1;
  }
  .screener_snapshot-table-body {
    @apply mt-0;

    td {
      @apply even:tabular-nums;
    }

    /* Keep in sync #stock-detail-etf-ellipsis */
    /* The constants are best effort, but may still cause some overflow */
    &.is-overflowing {
      td {
        width: auto;
        &.is-longer {
          width: 13%;
          max-width: 6.5vw;
          text-overflow: ellipsis;
          overflow: hidden;

          &.is-smaller-than-td {
            width: auto;
            max-width: none;
          }
        }
      }
    }
  }

  /** Chart Idea **/
  #idea-title-container {
    @apply flex h-8 items-center pt-2;
  }

  .chart-idea-title {
    @apply m-auto rounded-md border border-violet-400 bg-violet-100 px-1 py-px text-center font-sans !text-2xs !text-violet-600 font-medium dark:border-violet-500 dark:bg-violet-900 dark:!text-violet-200;
  }

  /* Investing channel ads selector, defined in their template and we can’t change it ourselves */
  .news-link-source {
    @apply text-2xs;
  }
}

.quote_statements-table {
  td,
  th {
    @apply h-auto py-1;
  }

  &.is-free {
    tr:last-child td {
      @apply last:rounded-br-none;
    }

    tr:nth-child(n + 2) td:nth-child(5) {
      @apply border-r dark:border-r-gray-700;
    }

    .elite-row:hover td:last-child {
      @apply border-primary border-t-secondary bg-transparent;
    }
  }
}

.quote-statements-elite-subscribe {
  @apply max-w-100 rounded-br-md border-b border-t-0 bg-primary text-center;
}
.quote-statements-elite-subscribe_title {
  @apply mt-4 text-sm font-bold;
}
.quote-statements-elite-subscribe_text {
  padding: 0 12%;
}
.quote-statements-elite-tooltip {
  @apply relative w-100 overflow-hidden rounded-md;
  background-image: url(/gfx/quote/blurred_chart.jpg);
  background-size: 400px 310px;
  height: 310px;
  .dark & {
    background-image: url(/gfx/quote/blurred_chart_dark.jpg);
  }
}
.quote-statements-elite-tooltip_wrapper {
  @apply flex h-full flex-col justify-center px-5 dark:bg-opacity-80 dark:text-white;
}
.quote_statements-tooltip {
  @apply rounded-md border border-primary bg-primary py-1.5 text-center shadow-modal;
}
.quote_statements-footer {
  @apply text-2xs leading-4 text-muted-2;
}

.quote_perf-table td {
  @apply h-6 border-b py-px;
}

.quote_perf-table-heading {
  @apply mb-4 mt-0 text-sm text-gray-400 font-bold;
}

/** Multiticker chart image **/
.multi-ticker-chart-container {
  @apply w-fit overflow-hidden rounded-md border border-primary bg-primary;
}

.quote_chart-image {
  @apply p-2;
}

.quote_title-wrapper {
  @apply mb-5 mt-8;
}
.quote_title {
  @apply text-xl text-muted-3 font-bold;

  b {
    @apply font-bold;
  }
}
.quote_title-company {
  @apply text-gray-400;
}

.quote_stocktwits {
  @apply relative rounded-md border border-primary py-1;
}
.quote_stocktwits-bottom-cover,
.quote_stocktwits-right-cover {
  @apply absolute bg-primary;
}
.quote_stocktwits-bottom-cover {
  @apply bottom-1 left-0 right-0 h-1;
}
.quote_stocktwits-right-cover {
  @apply bottom-1 right-0 top-1 w-1;
}

.quote_profile {
  @apply h-full p-0 text-2xs leading-4;
}
.quote_profile-bio,
.quote_profile-officers {
  @apply flex min-h-full w-full rounded-md border border-primary px-2 py-1 text-muted;
}
.quote_profile-officers {
  @apply flex-col space-y-1.5 py-2;
}

.quote_perf-tickers-input {
  @apply w-full text-2xs;
}

.quote-bar-charts-wrapper td {
  @apply py-7;
}
.quote-bar-chart {
  @apply text-3xs;

  .axis {
    .domain,
    line {
      @apply stroke-gray-100 dark:stroke-gray-600;
    }
    text {
      @apply fill-gray-400 font-sans; /* for some reason it won't take defined font on html */
    }
  }

  .grid path {
    @apply stroke-0;
  }

  .bar-chart-title {
    @apply fill-gray-600 text-2xs dark:fill-gray-400;
  }

  .grid .tick line {
    @apply stroke-gray-400 dark:stroke-gray-500;
    stroke-dasharray: 1;
    stroke-opacity: 0.3;
  }

  .bar--positive {
    @apply fill-blue-400;
  }

  .bar--negative {
    @apply fill-red-400;
  }
}
