.card-form {
  @apply rounded-xl border border-primary bg-secondary p-4 text-sm leading-[1.125rem] text-muted dark:bg-gray-750;
}

.card-form_input-group {
  @apply flex justify-between;
}

.card-form_input {
  @apply mb-4 flex flex-col;
}

.card-form_input-label {
  @apply mb-1.5 px-0;
}

.card-form_checkbox {
  @apply mb-4 ml-0.5 flex space-x-2;

  input {
    @apply top-px;
  }
}

.card-form_error {
  @apply -mt-2 mb-4 flex items-center text-2xs text-negative;

  &.is-primary {
    @apply mb-5 mt-0 rounded-md border border-red-500 bg-red-500 px-2 py-1.5 text-sm text-red-100 font-medium dark:bg-red-800;
  }
}

.card-form_footer {
  @apply text-muted-2 font-medium;
}
