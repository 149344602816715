body.is-insider {
  .fv-container {
    @apply px-2 xl:px-4;
    min-width: 1009px;
    &.is-full-width {
      max-width: 100%;
    }
  }

  b {
    @apply font-medium;
  }
}

.insider_filter {
  @apply text-2xs text-muted font-medium;
}

.insider_screener-link {
  @apply block p-0.5 text-2xs;
}

.insider_top-switch {
  @apply cursor-default leading-5 text-transparent;
}

.insider_header-row {
  @apply text-2xs;

  td {
    @apply first:w-1/12 last:w-1/12 last:whitespace-nowrap;
  }
}
