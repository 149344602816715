.highlighted-card {
  @apply rounded-md border-l-[6px] py-5 pr-4;
}

.highlighted-card {
  &,
  &.is-list,
  &.has-list ul {
    @apply pl-4;
  }

  &.is-list,
  &.is-list ul,
  &.has-list ul {
    @apply list-outside list-disc;
  }

  &.is-list {
    @apply pl-8;
  }

  &.is-list ul,
  &.has-list ul {
    @apply pl-6;
  }

  &.is-first {
    @apply rounded-b-none;
  }
  &.is-last {
    @apply rounded-t-none;
  }
  &.is-middle {
    @apply rounded-none;
  }

  &.is-neutral,
  &.is-positive,
  &.is-negative {
    @apply list-none bg-no-repeat pl-[52px];
    background-size: 24px 24px;
    background-position: 16px 20px;
  }

  &.is-primary {
    @apply border-l-blue-400 bg-blue-50 dark:border-l-blue-300 dark:bg-blue-900;
  }
  &.is-neutral {
    @apply border-l-gray-400 bg-secondary;
    background-image: url(/assets/icons/arrowLongRight.svg);
  }
  &.is-positive {
    @apply border-l-green-400 bg-green-50 dark:border-l-green-400 dark:bg-green-900;
    background-image: url(/assets/icons/trendingUp.svg);
  }
  &.is-negative {
    @apply border-l-red-400 bg-red-50 dark:border-l-red-400 dark:bg-red-900;
    background-image: url(/assets/icons/trendingDown.svg);
  }
}
