@media (max-width: 1024px) {
  body.is-futures,
  body.is-forex,
  body.is-crypto {
    &,
    .header-container,
    .fv-container {
      min-width: 994px;
    }
  }
  body.is-forex .fv-container,
  body.is-crypto .fv-container:not(.chart-menu_charts) {
    padding: 0 5px;
  }
}
