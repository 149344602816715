body.is-privacy {
  p {
    @apply my-2;
  }
}

.privacy_table {
  td,
  th {
    @apply text-sm;
  }
}
