body.is-save-to-portfolio .fv-select {
  @apply bg-primary;
}

.stp_table-form {
  @apply border-separate;
}
.stp_table-row {
  td {
    @apply border-primary bg-gray-50 pb-1.5 pt-0 font-sans text-2xs leading-5 text-gray-700 first:border-l first:pl-4 first:align-top first:leading-6 last:border-r last:pr-4 dark:bg-gray-750 dark:text-gray-200;
  }

  &.is-header td {
    @apply rounded-t-md border-y p-4 text-base leading-4 text-default font-bold;
  }
  &.is-footer td {
    @apply border-y p-3 first:rounded-bl-md last:rounded-br-md;
  }
  &.is-first td {
    @apply pt-4;
  }
  &.is-last td {
    @apply pb-4;
  }
}
