body.is-index {
  @apply min-w-[1205px];

  .fv-container {
    @apply p-0;
    min-width: 999px;
  }

  .styled-table-new th:last-child {
    @apply py-0 pr-0;
  }
}

.hp_chart {
  @apply mt-4.5 h-[184px] w-[334px] overflow-hidden rounded-md border border-primary px-1.5;

  .chart {
    @apply -mt-2;
  }
}

.hp_spacer,
.hp_spacer img {
  /* important is here beacuse all images have width in style */
  @apply !w-2 xl:!w-4;
}

.hp_map-wrapper {
  @apply mt-2 rounded-md border border-primary p-1.5 first:mt-5 xl:mt-4;
}

.hp_signal-table {
  /* we need extra padding on top/bottom to match recent quotes table height */
  tr:first-child td {
    padding-top: 3px;
  }
  tr:last-child td {
    padding-bottom: 3px;
  }

  svg {
    @apply inline-block rounded-sm align-top;
  }
}

.hp_table-earnings td {
  @apply text-muted-2;
}

.hp_label-container {
  @apply flex h-5 w-fit items-center whitespace-nowrap rounded pl-1 pr-0.5 leading-5;

  &.is-single-column {
    @apply w-full justify-between;
  }
  &.is-highlighted {
    @apply bg-yellow-100 dark:bg-yellow-700;

    a.tab-link {
      @apply text-blue-600 dark:text-blue-200;
    }
  }
}
.hp_label-container:hover {
  /* dark:bg had greater specificity that's why I had to target &, &.is-highlighted */
  &,
  &.is-highlighted {
    @apply bg-accent;
  }
}
.hp_label {
  @apply ml-1 py-px text-3xs leading-3 font-medium;
}

.hp_news-table {
  tr td {
    @apply py-px;
  }
  tr:first-child td {
    @apply pt-0.5;
  }
  tr:last-child td {
    @apply pb-0.5;
  }

  .nn-date {
    @apply w-15;
  }
}
.ticker-with-change-grid-table {
  tr:first-child td {
    @apply pt-px;
  }
  tr:last-child td {
    @apply pb-px;
  }
}

.ticker-with-change-grid-table tr td {
  @apply whitespace-nowrap p-0 leading-4 first:pl-px last:pr-px;
}

.ticker-with-change-grid-header {
  @apply h-5 px-1.5 py-0 text-2xs;

  b {
    @apply font-normal;
  }
}
/* rows wrapping tables quotes and news tables */
.recent-quotes-body,
.ticker-with-change-grid-body {
  @apply p-0;
}
/* min heights for recent quotes & major news */
.recent-quotes-body {
  @apply h-[82px] align-top;

  &.is-small {
    @apply h-[41px];
  }
}

.quote-table-container {
  @apply p-0;

  b {
    @apply font-normal;
  }
}
