body.is-account {
  .fw-title {
    @apply text-2xs font-medium;
  }

  .fv-button {
    @apply w-32;
  }

  .account-error-cell {
    @apply align-top text-red-500 dark:text-red-400;
  }

  .account-submit-cell {
    @apply pr-0;
  }
}
