.content-header {
  @apply mx-2 mb-4 mt-10 text-left;
}

.content-header_title {
  @apply text-3xl font-bold;
}

.content-header_subtitle {
  @apply mt-2 text-base text-muted-2 font-medium;
}
