body.is-news {
  .fv-container {
    min-width: 1009px;
  }
  .content {
    @apply mt-4;
  }
}

.news_time-table td {
  @apply pb-2;
}

.news_table-row {
  @apply cursor-pointer;

  td {
    @apply px-1 py-0.5 align-top leading-5;

    &.news_first-time-cell {
      @apply pr-0;
    }

    &.news_date-cell {
      @apply w-15 whitespace-nowrap pt-[3px];
    }

    &.news_link-cell > a {
      @apply inline-block pt-[3px] leading-4;
    }

    &.news_ad-icon-cell {
      @apply pl-[7px] pr-0;
    }

    &.news_heading-cell {
      @apply p-0.5;

      a {
        @apply pl-1 text-xl text-muted font-bold;
      }
      svg {
        @apply -mb-px inline align-sub;
      }
    }
  }
}

.news_favorite-blog-wrapper {
  @apply mt-3 w-full text-end text-2xs hover:no-underline;
}

.news_view-switch {
  @apply font-sans text-2xs text-muted-2;
}
.news_view-switch-label {
  @apply mr-1.5 inline-block font-medium;
}

.news_tooltip-tab {
  @apply rounded border-0 bg-gray-700 px-2 py-1.5 text-2xs text-gray-50 dark:bg-gray-200 dark:text-gray-700;
}

.news-badges-container {
  @apply relative top-px pt-0.5 leading-[15px];
}

.stock-news-label {
  @apply relative -top-px inline-flex h-4 select-none items-center gap-1 rounded-[0.188rem] text-3xs leading-none;
}

.news_table-row:hover a.fv-label {
  @apply border-white dark:border-black;

  &:hover {
    @apply border-current dark:border-current;
  }
}
