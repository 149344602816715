body.is-myaccount {
  .settings-table {
    @apply mb-8 last:mb-0;

    th {
      @apply text-base leading-5 font-medium;
    }
  }
}

.settings-table {
  th {
    @apply py-1.5 leading-4;
  }

  td {
    @apply py-0.5;
  }

  /* is-first and is-last classes are used in screener presets table */
  tr.is-first td,
  tr:first-child td {
    @apply pt-1.5;
  }
  tr.is-last td,
  tr:last-child td {
    @apply pb-1.5;
  }
}

.myaccount_heading-wrapper {
  @apply flex items-end space-x-4 pb-2 pl-1.5 text-muted;
}
.myaccount_heading-title {
  @apply text-base leading-5 font-medium;
}
.myaccount_heading-description {
  @apply text-2xs leading-4 text-muted-2;
}
