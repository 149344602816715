.news-table_wrapper {
  @apply rounded-md border border-blue-100 bg-blue-50 p-1 dark:border-[#1B3559] dark:bg-[#1C2331];
}

.news-table {
  @apply table-fixed;

  td {
    @apply px-0.5 text-2xs text-link first:rounded-l-sm first:align-baseline last:rounded-r-sm;
  }

  tr:hover td {
    @apply bg-blue-100 text-blue-600 dark:bg-[#1B3559] dark:text-blue-300;

    &:first-child,
    span {
      @apply text-muted;
    }
  }

  td:first-child,
  span {
    @apply text-muted-2 dark:text-gray-400;
  }
}
.news-link-container {
  @apply flex;
}
.news-link-left {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
.news-link-right {
  @apply flex items-center gap-1 whitespace-nowrap pl-1 text-2xs;
}

.news-calendar_heading {
  @apply mb-2 font-sans text-2xl capitalize text-muted font-bold;
}
