.alert {
  @apply flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs;

  &.is-success {
    @apply bg-[#008138] text-green-100 dark:border dark:border-green-500 dark:bg-green-800;

    .alert_icon {
      @apply dark:text-green-300;
    }
  }

  &.is-failure {
    @apply bg-[#D9222E] text-red-100 dark:border dark:border-red-500 dark:bg-red-800;

    .alert_icon {
      @apply dark:text-red-300;
    }
  }

  .alert_icon {
    @apply fill-current;
  }
}
