body.is-search {
  /** Headings for sections **/
  .section-heading {
    @apply pb-2 pt-4 font-sans text-base text-gray-600 font-bold dark:text-gray-200;

    .dot {
      @apply mx-1 text-gray-100 dark:text-gray-600;
    }
  }

  /** Tickers section **/
  .search-results_tickers {
    @apply bg-gray-50 dark:bg-gray-900;
  }

  /** Profile search results table **/
  .profile-results-table {
    @apply border-separate border-spacing-4 [--tw-border-spacing-x:0];
  }
  .search-results-profile-internal-table {
    @apply cursor-pointer rounded-md p-0 hover:bg-blue-50 dark:hover:bg-gray-700;

    &:hover td {
      @apply text-default;
    }
  }
  .profile-text {
    @apply py-1.5 leading-4 text-gray-600 dark:text-gray-200;
  }
}

.tickers-container {
  @apply flex w-full flex-wrap gap-4 pb-4;
}

/** Ticker card **/
.search-results_tickers_ticker {
  @apply flex h-[180px] w-[180px] flex-col rounded-md border border-gray-100 bg-white px-[10px] py-2 font-sans text-2xs text-gray-500 shadow-none hover:border-blue-400 hover:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-blue-400 dark:hover:text-gray-200;

  .ticker-heading {
    @apply text-link font-bold;
  }

  .ticker-footer {
    @apply text-gray-600 font-medium dark:text-gray-200;

    .dot {
      @apply text-gray-100 dark:text-gray-600;
    }
  }

  &:hover {
    .ticker-footer {
      @apply text-default;
    }
  }

  .ticker-heading {
    @apply mb-auto text-3xl;
  }

  .ticker-footer {
    @apply mt-4;
  }
}

.search-results_tickers_ticker_truncate {
  @apply block overflow-hidden text-ellipsis whitespace-nowrap;
}

.navbar-search-placeholder {
  @apply relative flex;

  .icon-wrapper {
    @apply pointer-events-none absolute top-0 ml-0.5 flex h-full w-7 items-center justify-center text-gray-600;
  }

  .icon {
    @apply pointer-events-none block h-4 w-4 fill-current text-gray-500;
  }

  .search-input {
    @apply h-8 w-full grow select-text appearance-none rounded-md border bg-transparent pl-7.5 pr-2 text-sm outline-none disabled:border-gray-700 disabled:bg-gray-700 disabled:text-gray-400 mobile:h-8 mobile:text-base;

    &.is-free {
      @apply border-gray-100 text-gray-750 dark:border-gray-600 dark:text-gray-50;
    }

    &.is-elite {
      @apply border-gray-600 text-gray-50;
    }
  }
}
