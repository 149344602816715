body.is-screener-presets {
  @apply text-2xs;

  .screener-presets-text {
    @apply text-center;
  }

  .screener-presets-error {
    @apply text-red-500 dark:text-red-400;
  }

  .styled-row td {
    @apply first:pr-0;
  }
}
